import React, { useState } from 'react';
import InputMask from "react-input-mask";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const FeedbackForm = () => {
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required('Заполните поле'),
    phone: yup.string().required('Заполните поле'),
    email: yup.string().email('Неверный формат email').required('Заполните поле'),
    comment: yup.string().required('Заполните поле'),
  });

  const { register, handleSubmit, watch, setValue, setError, clearErrors, formState: { errors }, control } = useForm({
    resolver: yupResolver(schema),
  });
  const watchAllFields = watch();

  const handleValidateUploadedFiles = (files) => {
    if(files?.length > 3) {
      setError('files', { type: 'maxFiles' });
      return;
    }

    let totalFilesWeight = 0;
    for (let i = 0; i < files.length; i++) {
      totalFilesWeight += files[i].size;
    }

    if(totalFilesWeight > 31457280) {
      setError('files', { type: 'maxFilesSize' });
      return;
    }

    clearErrors('files');
    return;
  }

  const handleUploadFiles = (e) => {
    handleValidateUploadedFiles(e.target.files);
  }

  const handlDeleteUploadedFile = (index) => {
    const fileListArr = Array.from(watchAllFields?.files);
    fileListArr.splice(index, 1);
    setValue('files', !!fileListArr?.length ? fileListArr : {});
    handleValidateUploadedFiles(fileListArr);
    return;
  }

  const onSubmit = async (data) => {
    const formData = new FormData();
    for (let i = 0; i < data?.files?.length; i++) {
      formData.append("files[]", data?.files[i]);
    }
    formData.append("name", data?.name);
    formData.append("phone", data?.phone);
    formData.append("email", data?.email);
    formData.append("comment", data?.comment);

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/feedback/`, formData);
      if (res.status === 200) {
        setSuccessSubmit(true);
      } else {
        setErrorSubmit(true);
      }
    } catch(e) {
      setErrorSubmit(true);
      console.log('e', e);
    }
  }

  return (
    <>
      <form className="w-100 fl-col rg-16" onSubmit={handleSubmit(onSubmit)} style={{display: errorSubmit || successSubmit ? 'none' : 'flex'}} >

          <div className="d-fl fl-w rg-16 cg-16">
              <div className="feedback--name input-container input-container--col input-container--md fade-in-bottom-small a-dr-04 a-dl-02 scroll-main">
                  <div className="input-content">
                      <label className="label label--required" htmlFor={"name"}>Имя</label>
                      <input
                        id="name"
                        type="text"
                        className={`input ${errors?.name ? "input--error" : ""}`}
                        defaultValue=""
                        {...register("name")}
                      />
                  </div>
                  <div className={'err-t'} style={{display: errors?.name ? 'block' : 'none'}}>{errors?.name?.message}</div>
              </div>
              <div className="feedback--phone input-container input-container--col input-container--md fade-in-bottom-small a-dr-04 a-dl-03 scroll-main">
                  <div className="input-content">
                      <label className="label label--required" htmlFor={"phone"}>Телефон</label>
                      <input
                        id="phone"
                        type="tel"
                        className={`input ${errors?.phone ? "input--error" : ""}`}
                        {...register("phone")}
                      />
                  </div>
                  <div className={'err-t'} style={{display: errors?.phone ? 'block' : 'none'}}>{errors?.phone?.message}</div>
              </div>
              <div className="feedback--email w-100 input-container input-container--col input-container--md fade-in-bottom-small a-dr-04 a-dl-04 scroll-main">
                  <div className="input-content">
                      <label className="label label--required" htmlFor={"email"}>Email</label>
                      <input
                        id="email"
                        type="email"
                        className={`input ${errors?.email ? "input--error" : ""}`}
                        {...register("email")}
                      />
                  </div>
                  <div className={'err-t'} style={{display: errors?.email ? 'block' : 'none'}}>{errors?.email?.message}</div>
              </div>

              <div className="input-container input-container--col input-container--md w-100 fade-in-bottom-small a-dr-04 a-dl-05 scroll-main">
                  <div className="input-content">
                      <label className="label label--required" htmlFor={"comment"}>Комментарий</label>
                      <textarea
                        id="comment"
                        type="comment"
                        rows="4"
                        className={`textarea input ${errors?.comment ? "input--error" : ""}`}
                        {...register("comment")}
                      />
                  </div>
                  <div className={'err-t'} style={{display: errors?.comment ? 'block' : 'none'}}>{errors?.comment?.message}</div>
              </div>

              <div>
                <input
                  type="file"
                  id="fileElem"
                  className="d-none"
                  accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .ppt, .pptx, .odt, .avi, .ogg, .m4a, .mov, .mp3, .mp4, .mpg, .wav, .wmv, .webp"
                  multiple
                  {...register("files", {
                    onChange: (e) => handleUploadFiles(e)
                  })}
                />
              </div>
          </div>

          {watchAllFields?.files && Object.entries(watchAllFields?.files).map(([key, value], i) => (
            <div className="d-fl ai-s cg-8 w-100 fade-in-bottom-small a-dr-04 scroll-main" key={key}>
              <span className="icon-success feedback__icon"></span>
              <div className="fl-g">
                <p className="feedback__file-name">{watchAllFields?.files[key]?.name}</p>
                <p className="feedback__file-size">{(watchAllFields?.files[key]?.size / (1024*1024)).toFixed(2)}MB</p>
              </div>
              <div className="btn btn--sm btn-delete btn-icon" title="Удалить" onClick={()=>handlDeleteUploadedFile(key)}><span className="icon-trash"></span></div>
            </div>
          ))}

          <div className="d-fl fl-col t-c mt-8 rg-8 ">
              <div className={'err-t'} style={{display: errors?.files?.type === 'maxFiles' ? 'block' : 'none'}}>Не больше 3 файлов</div>
              <div className={'err-t'} style={{display: errors?.files?.type === 'maxFilesSize' ? 'block' : 'none'}}>Не больше 30MB</div>

              <label className="btn btn--lg btn--secondary w-100 fade-in-top-small a-dr-04 a-dl-06 scroll-main" htmlFor="fileElem">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16"><path d="M5.348,2.268a6.323,6.323,0,0,1,8.41,0A5.254,5.254,0,0,1,15.5,6.134,5.251,5.251,0,0,1,13.758,10L7.751,15.523a4.444,4.444,0,0,1-3,1.144,4.447,4.447,0,0,1-3-1.144,3.687,3.687,0,0,1,0-5.523L7.751,4.477a2.71,2.71,0,0,1,3.6,0,2.212,2.212,0,0,1,0,3.314L5.348,13.314l-1.2-1.105,6.007-5.523a.736.736,0,0,0,0-1.1.9.9,0,0,0-1.2,0L2.946,11.105a2.21,2.21,0,0,0,0,3.313,2.707,2.707,0,0,0,3.6,0L12.557,8.9a3.687,3.687,0,0,0,0-5.523,4.515,4.515,0,0,0-6.007,0L1.744,7.791.543,6.686Z" transform="translate(-0.5 -0.667)"/></svg>
                  <span className="ml-8">Добавить файлы</span>
              </label>
              <div className="feedback__label t-r fade-in-top-small a-dr-04 a-dl-07 scroll-main">До 3 файлов. Общий размер - 30MB. </div>
          </div>

          <div className=" w-100 mt-24 fade-in-bottom-small a-dr-04 a-dl-08 scroll-main">
              <button className="btn btn--xl btn--primary t-r w-100" disabled={Object.entries(errors).length !== 0}>Отправить</button>
              <p className="feedback__policy mt-8">Нажимая кнопку «Отправить», вы подтверждаете свое <a href="https://storage.yandexcloud.net/mt-docs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95%20%D0%9D%D0%90%20%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3%20%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5%20%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5.htm" target="blank">согласие</a> на обработку персональных данных и ознакомление с <a href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.htm" target="blank">политикой</a> в отношении обработки персональных данных.
              </p>
          </div>

      </form>

      <div className="message fl-col h-100 ai-c jc-c mt-32" style={{display: successSubmit ? 'flex' : 'none'}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.331 114.297" className="message__icon message__icon--good render a-dr-08 js-scroll scroll-main">
            <path d="M65.025,122.116A57.157,57.157,0,1,1,88.267,12.751a3,3,0,0,1-2.442,5.481,51.167,51.167,0,1,0-38.557,94.733A51.166,51.166,0,0,0,116.166,65V60.017a3,3,0,1,1,6,0V65a57.088,57.088,0,0,1-57.141,57.116ZM65,78.888a3,3,0,0,0,2.121-.88l54.167-54.221a3,3,0,1,0-4.244-4.241L65,71.644,50.871,57.517a3,3,0,0,0-4.242,4.242l16.25,16.25A3,3,0,0,0,65,78.888Z" transform="translate(-7.835 -7.819)"></path>
        </svg>
        <div className="message__text"><div className="fade-in-bottom-small a-dl-03 a-dr-04 js-scroll scroll-main">Заявка успешно отправлена!</div>
            <div className="fade-in-bottom-small a-dl-04 a-dr-04 js-scroll scroll-main">Ожидайте ответа по почте.</div>
        </div>
      </div>

      <div className="message fl-col h-100 ai-c jc-c mt-32" style={{display: errorSubmit ? 'flex' : 'none'}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108" className="message__icon message__icon--bad render a-dr-08 js-scroll scroll-main scrolled">
            <path d="M54,108a54,54,0,1,1,54-54A54.061,54.061,0,0,1,54,108ZM54,6a48,48,0,1,0,48,48A48.055,48.055,0,0,0,54,6ZM42.233,73.121,71.564,43.79a3,3,0,1,0-4.242-4.242L37.991,68.879a3,3,0,1,0,4.242,4.242Zm28.446,0a3,3,0,0,0,0-4.242L41.347,39.547A3,3,0,0,0,37.1,43.791l29.332,29.33a3,3,0,0,0,4.242,0Z" />
        </svg>
        <div className="message__text fade-in-bottom-small a-dl-03 a-dr-04 js-scroll scroll-main">Во время отправки заявки произошла ошибка. Обновите страницу и попробуйте еще раз.
        </div>
      </div>
  </>
  )
}
