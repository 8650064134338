import { FeedbackForm } from "./feedbackForm";
import { isAndroid, isIOS, isMacOs, osName } from "react-device-detect";

// import Swiper core and required modules
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export const Main = () => {
    return (
        <div className="body">
            <input type="checkbox" id="feedback" className="checkbox" />
            <div className="modal modal--feedback d-fl jc-c ai-s pt-80 pb-40 pt-sm-40 px-16">
                <label title="Назад" htmlFor="feedback" className="link-arrow link-arrow--white link-arrow--left">
                    <span className="icon-back"></span>
                </label>
                <div className="feedback d-fl fl-col ai-c jc-c">
                    <h2 className="feedback__header mb-24 fade-in-top-small a-dr-04 scroll-main">Обратная связь</h2>
                    <FeedbackForm />
                </div>
            </div>
            <input type="checkbox" id="app" className="checkbox" />
            <div className="modal modal--app d-fl fl-col jc-s">
                <label title="Назад" htmlFor="app" className="link-arrow link-arrow--white link-arrow--left">
                    <span className="icon-back"></span>
                </label>

                <div className="pt-80 pt-xs-40 pb-60 px-24 d-fl fl-col ai-c">
                    <h2 className="h2 mb-40 mb-sm-40 t-c fade-in-top-small a-dr-04 scroll-main">Скачать приложение</h2>
                    <div className="tabs jc-c pb-24 fade-in-top-small a-dl-02 a-dr-05 scroll-main">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.kmz.mt.client"
                            className="tab-item tab-item--header"
                            target="blank"
                        >
                            Скачать для Android
                        </a>
                        <a
                            href="https://apps.apple.com/ru/app/%D1%87%D0%B5%D0%BB%D0%BD%D0%BE%D0%BA/id1645326928"
                            className="tab-item tab-item--header"
                            target="blank"
                        >
                            Скачать для iOS
                        </a>
                    </div>
                    <img
                        src="/img/qr-code.svg"
                        alt="Google play"
                        className="code-container__qr p-24 fade-in-bottom-small a-dl-04 a-dr-05 scroll-main"
                    />
                </div>
            </div>

            <main id="main" className="main">
                <header id="header" className="header px-0 px-xs-8 px-sm-0 px-md-24 px-lg-48 px-xl-80">
                    <div className="container-fixed d-fl ai-c jc-sb w-100">
                        <a href="#main" className="header__logo fade-in-top-small a-dr-05 scroll-main">
                            <svg width="48" height="48" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z"
                                    fill="white"
                                />
                                <path
                                    d="M9.41935 13.6188C17.3816 11.3753 21.3628 10.2535 24.1338 10.3392C33.6406 10.6333 41.2786 18.2714 41.5727 27.7781C41.6584 30.5491 40.5705 34.4102 38.3946 42.1323V42.1323C37.2969 46.0283 36.748 47.9763 36.2551 48.6933C34.3784 51.4236 31.3742 51.908 28.7345 49.9058C28.0413 49.38 26.7335 47.4449 24.1178 43.5746C19.926 37.3724 14.8647 32.3805 7.76464 27.7493C3.9662 25.2716 2.06698 24.0328 1.50947 23.2893C-0.401212 20.7412 0.0606961 17.7191 2.64526 15.8581C3.39939 15.315 5.40605 14.7496 9.41935 13.6188V13.6188Z"
                                    fill="#7E50FB"
                                />
                                <path
                                    d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z"
                                    fill="#FFD000"
                                />
                            </svg>
                        </a>

                        <input id="globalMenu" type="checkbox" className="header-menu-control" />
                        <label htmlFor="globalMenu" className="burger-menu ml-24 fade-in-top-small a-dr-05 a-dl-02 scroll-main">
                            <span className="menu-line"></span>
                        </label>
                        <div id="sideManu" className="menu-container ai-c">
                            <label title="Назад" htmlFor="globalMenu" className="link-arrow link-arrow--white link-arrow--left d-md-none">
                                <span className="icon-back"></span>
                            </label>
                            <div className="d-fl fl-col fl-md-row ai-md-c w-100 jc-sm-c rg-24 cg-24 cg-lg-40 fade-in-top-small a-dr-05 a-dl-04 scroll-main">
                                <a href="#about" className="menu-container__link menu">
                                    О нас
                                </a>
                                <a href="#how-to" className="menu-container__link menu">
                                    Как заказать{" "}
                                </a>
                                <a href="#map" className="menu-container__link menu">
                                    Где работаем
                                </a>
                                <div className="d-fl fl-col rg-16 pt-24 d-md-none">
                                    <a
                                        href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.htm"
                                        target="_blank"
                                        className="menu-container__link docs"
                                    >
                                        Политика Конфиденциальности
                                    </a>
                                    <a
                                        href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B2%20%D1%81%D0%B0%D0%BB%D0%BE%D0%BD%D0%B5%20%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2%D0%B0.htm"
                                        target="_blank"
                                        className="menu-container__link docs"
                                    >
                                        Правила поведения в транспортном средстве
                                    </a>
                                    <a
                                        href="https://storage.yandexcloud.net/mt-docs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95%20%D0%9D%D0%90%20%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3%20%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5%20%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5.htm"
                                        target="_blank"
                                        className="menu-container__link docs"
                                    >
                                        Согласие на обработку персональных данных
                                    </a>
                                    <a
                                        href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.htm"
                                        target="_blank"
                                        className="menu-container__link docs"
                                    >
                                        Пользовательское соглашение мобильного приложения
                                    </a>
                                </div>
                            </div>
                            <div className="fl-col fl-md-row rg-16 cg-8 mt-24 mt-md-0 w-100 w-md-auto btn-container fade-in-top-small a-dr-05 a-dl-04 scroll-main">
                                <label htmlFor="app" className="btn btn--lg  btn--red w-100 w-md-auto fl-md-o-2">
                                    Скачать приложение
                                </label>
                                <label htmlFor="feedback" className="btn btn--lg  btn--secondary w-100 w-md-auto fl-md-o-1">
                                    Напишите нам
                                </label>
                            </div>
                        </div>
                        <div className="d-fl cg-12 ai-c jc-end fl-g d-md-none fade-in-top-small a-dr-05 a-dl-04 scroll-main">
                            <label htmlFor="feedback" className="btn btn--xl btn-icon btn--secondary">
                                <span className="icon-chat"></span>
                            </label>
                            <label htmlFor="app" className="btn btn--xl btn-icon btn--red">
                                <span className="icon-download"></span>
                            </label>
                        </div>
                    </div>
                </header>

                <div className="main-container d-fl fl-col jc-c ai-c">
                    <h1 className="main__header mr-md-80 slide-left a-dr-07 scroll-main">Челнок</h1>
                    <p className="main__text mt-12 mr-md-80 slide-right a-dr-07 a-dl-02 scroll-main">
                        мобильное приложение для ваших поездок
                    </p>
                    <label
                        htmlFor="app"
                        className="btn btn--xxl btn--cta mt-40 mb-24 mr-md-80 fade-in-top-small a-dr-05 a-dl-04 scroll-main"
                    >
                        Скачать приложение
                    </label>
                </div>
            </main>

            <div className="d-fl fl-col ai-c px-16 px-xs-24 px-lg-40 px-xl-80">
                <section id="about" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="about container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Что такое Челнок?</h2>
                        <p className="services__text mt-20">
                            Челнок — это новый способ перемещения по городу, гибрид общественного транспорта и такси. Маршрут наших машин не
                            фиксирован, а проходит между остановками, которые выбираете вы и другие пассажиры.
                        </p>
                        <div className="d-fl fl-w fl-md-nw jc-c ai-c rg-48 cg-40 mt-48 mt-sm-60 mt-md-80">
                            <div className="left-container w-100 w-md-50 d-fl fl-col rg-32 rg-xl-40">
                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/about-icons/icon-comfort.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Комфорт</h3>
                                        <p className="services__text mt-12">
                                            Удобные мягкие кресла, кондиционер в салоне, не нужно ждать транспорта на остановке. Оплата в
                                            мобильном приложении.
                                        </p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/about-icons/icon-security.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Безопасность</h3>
                                        <p className="services__text mt-12">
                                            Только сидячие места, есть ремни безопасности и детские кресла.
                                        </p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/about-icons/icon-benefit.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Выгода</h3>
                                        <p className="services__text mt-12">По функционалу и комфорту как такси, а по цене дешевле.</p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/about-icons/icon-care.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Забота</h3>
                                        <p className="services__text mt-12">
                                            У нас работают только вежливые водители. Оперативно и на высшем уровне работает техническая
                                            поддержка для пассажиров во время работы сервиса.
                                        </p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/about-icons/icon-animals.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Можно с животными</h3>
                                        <p className="services__text mt-12">Перевозим кошек и собак средних размеров.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="right-container w-100 w-md-50 fade-in-bottom-small a-dr-08 js-scroll">
                                <img className="img about__img" src="img/car-photo.jpg" alt="Челнок пригодится всегда" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="about container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Челнок — транспорт для современного города</h2>
                        <div className="d-fl fl-w fl-md-nw jc-c rg-48 cg-40 mt-48 mt-sm-60 mt-md-80">
                            <div className="left-container w-100 w-md-50 d-fl fl-col rg-32 rg-xl-40">
                                <div className="d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="about__icon">
                                        <span className="icon-smile"></span>
                                    </div>
                                    <div>
                                        <h3 className="about__header t-db">Поездки с комфортом</h3>
                                        <p className="about__text mt-8">Cвободные места, удобные кресла, кондиционер, вежливые водители</p>
                                    </div>
                                </div>

                                <div className="d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="about__icon">
                                        <span className="icon-percent"></span>
                                    </div>
                                    <div>
                                        <h3 className="about__header t-db">Экономия на поездках</h3>
                                        <p className="about__text mt-8">Стоимость поездки ниже, чем у такси, а уровень комфорта тот же</p>
                                    </div>
                                </div>

                                <div className="d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="about__icon">
                                        <span className="icon-road"></span>
                                    </div>
                                    <div>
                                        <h3 className="about__header t-db">Нет пересадок</h3>
                                        <p className="about__text mt-8">Челнок без пересадок довезет вас до нужной остановки</p>
                                    </div>
                                </div>

                                <div className="d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="about__icon">
                                        <span className="icon-card"></span>
                                    </div>
                                    <div>
                                        <h3 className="about__header t-db">Удобная оплата</h3>
                                        <p className="about__text mt-8">Оплачивайте поездки банковской картой (MasterCard, Visa, Мир)</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="right-container w-100 w-md-50 about__img fade-in-bottom-small a-dr-05 js-scroll"></div> */}
                            <div className="right-container w-100 w-md-50 fade-in-bottom-small a-dr-08 js-scroll">
                                <img className="img about__img" src="img/chelnok-about.jpg" alt="Челнок пригодится всегда" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="services" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="services container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Челнок пригодится всегда</h2>

                        <div className="d-fl fl-w fl-md-nw ai-md-c jc-c rg-48 cg-40 mt-48 mt-sm-60 mt-md-80">
                            <div className="left-container w-100 w-md-50 d-fl fl-col rg-24 rg-lg-32">
                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/services-icons/icon-traffic-light.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Разгрузка дорожной ситуации</h3>
                                        <p className="services__text mt-12">
                                            У нас динамические маршруты, развозим людей по всей зоне работы сервиса. Одновременно в
                                            транспортном средстве может быть несколько пассажиров, которым будет «по пути».
                                        </p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/services-icons/icon-modernity.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Современность</h3>
                                        <p className="services__text mt-12">Стильное мобильное приложение, оплата происходит онлайн.</p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/services-icons/icon-support.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Постоянная поддержка сервиса</h3>
                                        <p className="services__text mt-12">
                                            Оперативно и на высшем уровне работает техническая поддержка для пассажиров и водителей во время
                                            работы сервиса.
                                        </p>
                                    </div>
                                </div>

                                <div className="services__item d-fl ai-s cg-16 cg-sm-24 rg-24 fade-in-top-small a-dr-06 js-scroll">
                                    <div className="services__icon">
                                        <img src="img/services-icons/icon-driver.svg" />
                                    </div>
                                    <div>
                                        <h3 className="services__header t-db">Комфортные условия труда для водителей</h3>
                                        <p className="services__text mt-12">Проводится специальное обучение.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="right-container w-100 w-md-50 fade-in-bottom-small a-dr-08 js-scroll">
                                <img className="img" src="img/services.svg" alt="Челнок пригодится всегда" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="how-to" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="how-to container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Как заказать поездку</h2>
                        <div className="d-fl fl-w fl-md-nw jc-c ai-md-c rg-48 cg-40 mt-48 mt-sm-60 mt-md-80">
                            <div className="left-container w-100 w-md-40 pr-md-40 slide-left a-dr-06 js-scroll d-none d-md-b">
                                <img className="img" src="img/how-to.svg" alt="Как заказать поездку" />
                            </div>
                            <div className="right-container w-100 w-md-60 slide-right a-dr-06 js-scroll">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="slider-swiper"
                                >
                                    <SwiperSlide>
                                        <div className="d-fl ai-c jc-c px-80 fl-w fl-md-nw">
                                            <div className="mb-16">
                                                <img src="img/slider/slide-1.png" alt="Как заказать поездку" />
                                            </div>
                                            <div className="pl-md-40 d-fl fl-md-col cg-16 pb-36">
                                                <div>
                                                    <img
                                                        className="slider-image pb-md-48"
                                                        src="img/slider/icon-download.png"
                                                        alt="Как заказать поездку"
                                                    />
                                                </div>
                                                <p className="slider-text">
                                                    Скачайте приложение <b>Челнок</b> и зарегистрируйтесь, привяжите карту
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-fl ai-c jc-c px-80 fl-w fl-md-nw">
                                            <div className="mb-16">
                                                <img src="img/slider/slide-2.png" alt="Как заказать поездку" />
                                            </div>
                                            <div className="pl-md-40 d-fl fl-md-col cg-16 pb-36">
                                                <div>
                                                    <img
                                                        className="slider-image pb-md-48"
                                                        src="img/slider/icon-route.png"
                                                        alt="Как заказать поездку"
                                                    />
                                                </div>
                                                <p className="slider-text">
                                                    Выберите <b>начальные и конечные точки</b> на карте или введите их в адресной строке
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-fl ai-c jc-c px-80 fl-w fl-md-nw">
                                            <div className="mb-16">
                                                <img src="img/slider/slide-3.png" alt="Как заказать поездку" />
                                            </div>
                                            <div className="pl-md-40 d-fl fl-md-col cg-16 pb-36">
                                                <div>
                                                    <img
                                                        className="slider-image pb-md-48"
                                                        src="img/slider/icon-location.png"
                                                        alt="Как заказать поездку"
                                                    />
                                                </div>
                                                <p className="slider-text">
                                                    Нажмите кнопку <b>«Заказать»</b> и <b>пройдите</b> к месту посадки
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-fl ai-c jc-c px-80 fl-w fl-md-nw">
                                            <div className="mb-16">
                                                <img src="img/slider/slide-4.png" alt="Как заказать поездку" />
                                            </div>
                                            <div className="pl-md-40 d-fl fl-md-col cg-16 pb-36">
                                                <div>
                                                    <img
                                                        className="slider-image pb-md-48"
                                                        src="img/slider/icon-smile.png"
                                                        alt="Как заказать поездку"
                                                    />
                                                </div>
                                                <p className="slider-text">
                                                    <b>Наслаждайтесь комфортной поездкой</b> и не забудьте оценить наш сервис
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="gallery" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="gallery container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Пассажиры Челнока</h2>
                        <div className="mt-48 mt-sm-60 mt-md-80">                            
                            <div className="row">
                                <div className="column">
                                    <img src="img/gallery/image-6.jpg" alt="Фотография водителя за в кепке с логотипом Челнока."/>
                                    <img src="img/gallery/image-4.jpg" alt="Девушка с собачкой на руках на фоне Челнока"/>                                                                                                     
                                </div>
                                <div className="column">
                                    <img src="img/gallery/image-1.jpg" alt="Девочка улыбается и поднимается на ступеньку Челнока"/>
                                    <img src="img/gallery/image-5.jpg" alt="Фотография экрана мобильного телефона с открытым приложением Челнок"/>
                                </div>
                                <div className="column">
                                    <img src="img/gallery/image-3.jpg" alt="Пассижиры в салоне Челнока"/>
                                    <img src="img/gallery/image-7.jpg" alt="Фотография пристёгнутой ремнями безопасности девочки на пассажирском сиденье"/>                                                                        
                                </div>
                                <div className="column">
                                    <img src="img/gallery/image-8.jpg" alt="Группа людей заказывает Челнок с помощью смартфона"/>
                                    <img src="img/gallery/image-9.jpg" alt="Девушка сканирует штрих код на двери Челнока"/>                                                                                                        
                                </div>
                            </div>                            
                        </div>
                    </div>
                   
                </section>

                <div className="gallery-fullpage"></div>

                <section id="map" className="section section--dark-blue t-white mt-24 pb-40 fade-in-bottom a-dr-05 js-scroll">
                    <div className="map container-fixed">
                        <h2 className="h2 mb-40 fade-in-top-small a-dr-06 js-scroll">Москва</h2>
                        <p className="t-lg fade-in-top-small a-dr-06 js-scroll">Челнок работает с 5:30 до 23:00</p>
                        <p className="t-lg mb-40 fade-in-top-small a-dr-06 js-scroll">Сервис предоставляется совместно с Департаментом транспорта Москвы</p>
                        <img className="img fade-in-bottom-small a-dr-06 js-scroll" src="img/map-moscow.svg" alt="Москва" />
                    </div>
                </section>

                <section id="map-chelny" className="section section--dark-blue t-white mt-24 pb-40 fade-in-bottom a-dr-05 js-scroll">
                    <div className="map container-fixed">
                        <h2 className="h2 mb-40 fade-in-top-small a-dr-06 js-scroll">Набережные Челны</h2>
                        <p className="t-lg fade-in-top-small a-dr-06 js-scroll">Челнок работает с 6:00 до 23:00</p>
                        <p className="t-lg fade-in-top-small a-dr-06 js-scroll">Зона работы сервиса &mdash; Новый город</p>
                        <p className="t-lg mt-8 mb-40 fade-in-top-small a-dr-06 js-scroll">Перевозчик: ООО «КАМАЗ-Спец-транс»</p>
                        <img className="img fade-in-bottom-small a-dr-06 js-scroll" src="img/map-chelny.svg" alt="Набережные Челны" />
                    </div>
                </section>

                <section id="map-skolkovo" className="section section--dark-blue t-white mt-24 pb-40 fade-in-bottom a-dr-05 js-scroll">
                    <div className="map container-fixed">
                        <h2 className="h2 mb-40 fade-in-top-small a-dr-06 js-scroll">Сколково</h2>
                        <p className="t-lg fade-in-top-small a-dr-06 js-scroll">Челнок работает с 7:00 до 21:00</p>
                        <p className="t-lg mb-40 fade-in-top-small a-dr-06 js-scroll">Сервис предоставляется совместно Департаментом транспорта Москвы</p>
                        <img className="img fade-in-bottom-small a-dr-06 js-scroll" src="img/map-skolkovo.svg" alt="Новый Город" />
                    </div>
                    <section className="section">
                        <div id="beta" className="beta container-fixed">
                            <div className="d-fl fl-w ai-md-c fl-md-nw rg-48 cg-40">
                                <div className="left-container w-100 w-md-50"></div>
                                <div className="right-container w-100 w-md-50 pt-md-24">
                                    <p className="beta__text slide-right a-dr-06 js-scroll">Возникли вопросы?</p>
                                    <p className="beta__text mt-12 slide-right a-dr-06 js-scroll">Есть предложения?</p>
                                    <p className="beta__text mt-12 slide-right a-dr-06 js-scroll">Нашли баги в работе приложения?</p>
                                    <label htmlFor="feedback" className="btn btn--lg btn--info mt-40 slide-right a-dr-06 js-scroll">
                                        Напишите нам
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <footer id="footer" className="footer container-fixed mt-24">
                    <div className="d-fl fl-col fl-sm-row jc-sb cg-16 rg-32 mt-40">
                        <div className="w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Перевозчик ООО «Камаз-спец-транс»</h4>
                            <p className="t-xs">ИНН 1657264932</p>
                            <p className="t-xs mt-4">ОГРН 1201600079394</p>
                            <p className="t-xs mt-12">
                                423800, Республика Татарстан, город Набережные Челны, улица Машиностроительная, дом 105
                            </p>
                        </div>

                        <div className="w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Разработчик ООО «Цифровая платформа КАМАЗ»</h4>
                            <p className="t-xs">ИНН 1650369414</p>
                            <p className="t-xs mt-4">ОГРН 1650369414</p>
                            <p className="t-xs mt-12">423827, Республика Татарстан, город Набережные Челны, проспект Автозаводский, дом 2</p>
                        </div>

                        <div className="d-fl fl-col w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Документы</h4>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b"
                            >
                                Политика Конфиденциальности
                            </a>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B2%20%D1%81%D0%B0%D0%BB%D0%BE%D0%BD%D0%B5%20%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2%D0%B0.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Правила поведения в транспортном средстве
                            </a>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95%20%D0%9D%D0%90%20%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3%20%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5%20%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Согласие на обработку персональных данных
                            </a>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Пользовательское соглашение мобильного приложения
                            </a>
                        </div>

                        <div className="w-100 w-sm-auto fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Мы в соц. сетях</h4>
                            <a href="https://t.me/chelnok_rf" target="blank" className="d-fl ai-c cg-12">
                                <span className="footer__icon icon-telegram"></span>
                                <p target="blank" className="footer__link t-sm">
                                    Телеграм
                                </p>
                            </a>

                            <a href="https://vk.com/chelnok_nch" target="blank" className="d-fl ai-c cg-12 mt-12">
                                <span className="footer__icon icon-vk"></span>
                                <p target="blank" className="footer__link t-sm">
                                    Вконтакте
                                </p>
                            </a>

                            <h4 className="footer__header t-m mt-20">Наша почта</h4>
                            <a href="mailto:support@chelnok.space&subject=Обратная связь Челнок" className="d-fl ai-c cg-12 mt-12">
                                <span>
                                    <img src="img/email.svg" />
                                </span>
                                <p target="blank" className="footer__link t-sm">
                                    support@chelnok.space
                                </p>
                            </a>
                        </div>
                    </div>

                    <div className="d-fl fl-w jc-sb mt-40 mb-16">
                        <p className="footer__label">Челнок 2024 г.</p>
                        <p className="footer__label">Все права защищены</p>
                    </div>
                </footer>
            </div>
        </div>
    );
};
