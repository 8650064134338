import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Helmet } from "react-helmet";

import Main from "./pages/main";
import Page404 from "./pages/page404";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        const hash = window.location.hash;

        /**
         * По задачи от дизайнера добавляем редирект на тильду
         */
        if (window.location.pathname === "/intercity") {
            window.location.href = process.env.REACT_APP_REDIRECT_INTERCITY_URL;
        }

        /**
         * Если в url присутствует якорь, то делаем плавную прокрутку до него
         */
        const section = hash ? document.querySelector(hash) : null;

        if (section) {
            setTimeout(() => {
                section.scrollIntoView({ block: "start", behavior: "smooth" });
            }, 100);
        }
    });

    return (
        <>
            <Helmet>
                <title>Челнок - сервис для комфортных поездок</title>
                <meta name="description" content="Челнок — мобильное приложение для твоих поездок" />
                <link href="/css/main.css" rel="stylesheet" />
                <script src="/js/app.js" type="text/javascript" />
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
